import Icon from './Icon'

interface Props {
    url: string,
    iconName?: string,
    iconSize?: number,
    imageRef?: string,
    imageAlt?: string,
    text?: string,
    id?: string,
    className?: string,
    style?: React.CSSProperties
}

const Clickable = ({ url, iconName, iconSize, imageRef, imageAlt, text, id, className, style }: Props) => {
    if (!iconName && !imageRef && !text) {
        return <a href={url} style={style}>No image, text or icon</a>
    }

    return (
        <a id={id} className={className} href={url}>
            {iconName &&
                <Icon iconName={iconName} size={iconSize} style={style} />
            }

            {imageRef &&
                <img src={imageRef} alt={imageAlt} style={style} />
            }

            {text}
        </a>
    )
}

export default Clickable
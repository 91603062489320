import { IconType } from 'react-icons';
import * as aiIcons from 'react-icons/ai';
import * as fiIcons from 'react-icons/fi';

interface Props {
    iconName: string,
    size?: number,
    id?: string,
    className?: string,
    style?: React.CSSProperties
}

const Icon = ({ iconName, size, id, className, style }: Props) => {
    const getIcons = (iconName: string) => {
        const iconsMap = new Map();
        iconsMap.set('Ai', aiIcons);
        iconsMap.set('Fi', fiIcons);

        return iconsMap.get(iconName.substring(0, 2));
    };

    const icons: any = getIcons(iconName);
    const TheIcon: IconType = icons[iconName];

    return <TheIcon size={size} style={style} id={id} className={className} />;
}

export default Icon
import { useEffect, useRef, useState } from 'react'
import './Typewriter.css';

interface Props {
    text: string,
    cursor?: string,
    delayInMS?: number,
    className?: string,
}

const Typewriter = ({ text, cursor, delayInMS, className }: Props) => {
    const [progress, setProgress] = useState('');
    const [paused, setPaused] = useState(true);
    const index = useRef(0);

    useEffect(() => {
        setTimeout(() => setPaused(false), delayInMS ?? 0);
    }, []);

    useEffect(() => {
        if (paused) return;

        setTimeout(() => {
            const currentCharacter = text.charAt(index.current)
            setProgress(previous => previous + currentCharacter);
            index.current++;
        }, 150);
    }, [progress, !paused]);


    if (cursor) return <h1 className={className}>{progress}<span id='Cursor'>{cursor}</span></h1>
    return <h1 className={className}>{progress}</h1>
}

export default Typewriter
import './App.scss';
import Tux from './assets/Tux-color.svg';
import Clickable from './components/Clickable';
import NoScroll from './components/NoScroll';
import Typewriter from './components/Typewriter';

function App() {
  return (
    <div id='App'>
      <Typewriter text='Elias De&nbsp;Smet' cursor='_' delayInMS={2500} className='title' />

      <div className='socials'>
        <Clickable url='https://github.com/dselias' iconName={'AiOutlineGithub'} iconSize={50} className='fade' />
        <Clickable url='https://www.linkedin.com/in/eliasds/' iconName={'AiFillLinkedin'} iconSize={50} className='fade' />
        <Clickable url='mailto:site@eliasdesmet.com' iconName={'FiMail'} iconSize={50} className='fade' />
      </div>

      <NoScroll />

      <Clickable url='https://archlinux.org/' imageRef={Tux} imageAlt='Tux' className='tux fade' />
    </div>
  );
}

export default App;


import NoscrollIcon from '../assets/No-scroll.svg';
import { useEffect, useState } from 'react';
import './NoScroll.scss';


const NoScroll = () => {
    const [scrollCount, setScrollCount] = useState(0);
    const [text, setText] = useState('');
    let timer: NodeJS.Timeout;

    const handleScrollEvent = (event: Event) => {
        clearTimeout(timer);
        timer = setTimeout(hideNoScroll, 3000);
        setScrollCount(previous => previous + 1);

        document.querySelector<HTMLElement>('.noScroll')!.style.opacity = '100';
    }

    const hideNoScroll = () => {
        document.querySelector<HTMLElement>('.noScroll')!.style.opacity = '0';
        document.querySelector<HTMLElement>('.noScroll p')!.style.opacity = '0';
    }

    //Scroll listener
    useEffect(() => {
        document.addEventListener('wheel', handleScrollEvent);
        return () => document.removeEventListener('wheel', handleScrollEvent);
    }, []);

    useEffect(() => {
        if (scrollCount === 50) setText("Alright, you can stop now.");
        if (scrollCount === 100) setText("Didn't you scroll enough already?");
        if (scrollCount === 200) setText("Do you think this is some sort of minigame?");
        if (scrollCount > 300) setText(scrollCount + 'x');
    }, [scrollCount]);

    useEffect(() => {
        document.querySelector<HTMLElement>('.noScroll p')!.style.opacity = '100'
    }, [text]);


    return (
        <div className='noScroll fade'>
            <img src={NoscrollIcon} alt='Crossed mouse icon' />
            <p className='fade'>{text}</p>
        </div>
    )
}

export default NoScroll